// src/auth-service.js
import { auth } from './firebase-config';
import { GoogleAuthProvider, signInWithPopup, signOut as firebaseSignOut } from 'firebase/auth';

export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider);
};

export const signOut = () => {
  firebaseSignOut(auth);
};
