import React, { useEffect, useState } from 'react';
import { auth } from './firebase-config'; // Adjust path as necessary
import { signInWithGoogle, signOut } from './auth-service'; // Adjust path as necessary
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Footer from './Footer';
import NavigationBar from './NavigationBar';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [authError, setAuthError] = useState('');

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // Here you can add your logic to check if the user's email is allowed
        const isAllowedEmail = checkAllowedEmail(user.email);
        if (!isAllowedEmail) {
          // If the email is not allowed, sign out the user and set an error message
          signOut();
          setAuthError('Access is restricted. Please contact the administrator if you believe this is an error.');
        } else {
          setCurrentUser(user);
          setAuthError(''); // Clear any previous error messages
        }
      } else {
        setCurrentUser(null);
      }
    });
  }, []);

  const checkAllowedEmail = (email) => {
    // Define allowed emails or domains
    const allowedEmails = ['joe@stevensonprice.com','martin@stevensonprice.com']; // Example allowed email
    const allowedDomains = ['yourcompany.com']; // Example allowed domain

    return allowedEmails.includes(email) || allowedDomains.some(domain => email.endsWith(`@${domain}`));
  };

  return (
    <div className="App">
      <NavigationBar currentUser={currentUser} signOut={signOut} />
      {currentUser ? (
        <>
          <div>
            <h1>Welcome, {currentUser.displayName}</h1>
          </div>
          <Footer />
        </>
      ) : (
        <div className="text-center mt-5">
          <h2>Please Sign In</h2>
          <button className="btn btn-secondary" onClick={signInWithGoogle}>Sign In with Google</button>
          {authError && <p className="text-danger">{authError}</p>}
          <Footer />
        </div>
      )}
    </div>
  );
}

export default App;
