import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import './App.css'; // Or the appropriate path to your CSS file

const NavigationBar = ({ currentUser, signOut }) => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="#home">Stevenson Price Ltd</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {currentUser && (
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Data Processing" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">YouTube Monthly Refresh</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.1">YouTube Reports for Finance</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">YouTube Final Tables, Force Refresh</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Tubular Labs Creator Trends Ingest</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Tubular Labs Top 10 Videos Ingest</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Nielsen Data Ingest</NavDropdown.Item>
              {/* Commented out for future use
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Separated Link</NavDropdown.Item>
              */}
            </NavDropdown>
            <NavDropdown title="Reference Data" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Dim Map Brand</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.1">Dim Map Channel</NavDropdown.Item>              
            </NavDropdown>
            <NavDropdown title="Data Analysis" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Brand Analytics Day</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Brand Analytics Month</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Forecasting</NavDropdown.Item>
              {/*<NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4" className="ask-hal-dropdown-item">Not needed</NavDropdown.Item>*/}            
            </NavDropdown>            
            <Nav.Link href="#settings" id='ask-hal'>Ask HAL</Nav.Link>
          </Nav>
          
          <Nav className="ms-auto">
            <NavDropdown title="Admin" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">User Management</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Airflow</NavDropdown.Item>              
            </NavDropdown>            
            
            <Nav>
              {/* Replace href with onClick to call the signOut function */}
              <Nav.Link onClick={signOut}>Logout</Nav.Link>
            </Nav>          
          </Nav>
          
        </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
