import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="footer bg-dark text-white mt-5">
      <Container className="text-center py-3">
        {/* Content of the footer */}
        <span>© {new Date().getFullYear()} Stevenson Price Ltd. All rights reserved.</span>
      </Container>
    </footer>
  );
};

export default Footer;
