// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// Make sure to import getAuth
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAiNpf2LhBZpbzoa2vRGiiNZq-cwS4sEEw",
  authDomain: "stg-sp-webapp.firebaseapp.com",
  projectId: "stg-sp-webapp",
  storageBucket: "stg-sp-webapp.appspot.com",
  messagingSenderId: "734547026929",
  appId: "1:734547026929:web:0441befb74dc3a16e35a50",
  measurementId: "G-PVRTWV5QDW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialise firebase Auth and export
export const auth = getAuth(app); // Correctly initialize Auth for Firebase v9

export default app;